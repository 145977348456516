import simpleParallax from 'simple-parallax-js';

let parallaxImagesSlow = document.getElementsByClassName('parallax-slow');
let parallaxImagesMedium = document.getElementsByClassName('parallax-medium');
let parallaxImagesFast = document.getElementsByClassName('parallax-fast');

new simpleParallax(parallaxImagesSlow, {
  overflow: true,
  scale: 3,
});

new simpleParallax(parallaxImagesMedium, {
  overflow: true,
  scale: 2,
});

new simpleParallax(parallaxImagesFast, {
  overflow: true,
  scale: 3,
});
