// Example starter JavaScript for disabling form submissions if there are invalid fields
(() => {
  'use strict'

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }

      // Get all the error messages
      const errors = form.querySelectorAll('.invalid-feedback');

      // Get the error alert element
      const errorAlert = form.querySelector('.alert-danger');

      // Prepend the error messages in a list to the top of the form
      const errorList = document.createElement('ul');
      errors.forEach(error => {
        const errorItem = document.createElement('li');
        errorItem.textContent = error.textContent;
        errorList.appendChild(errorItem);
      });

      errorAlert.prepend(errorList);
      errorAlert.classList.remove('d-none');

      form.classList.add('was-validated')
    }, false)
  })
})()
