document.addEventListener("DOMContentLoaded", function() {
  var headers = document.querySelectorAll(".faq-header");

  headers.forEach(function(header) {
    header.addEventListener("click", function() {
      var body = header.nextElementSibling;
      var allBodies = document.querySelectorAll(".faq-body");
      var allPlusIcons = document.querySelectorAll(".faq-header .plus-icon");
      var allXIcons = document.querySelectorAll(".faq-header .x-icon");

      if (body.style.display === "block") {
        body.style.display = "none";
        header.querySelector(".plus-icon").style.display = "inline";
        header.querySelector(".x-icon").style.display = "none";
      } else {
        allBodies.forEach(function(b) {
          b.style.display = "none";
        });
        allPlusIcons.forEach(function(icon) {
          icon.style.display = "inline";
        });
        allXIcons.forEach(function(icon) {
          icon.style.display = "none";
        });
        body.style.display = "block";
        header.querySelector(".plus-icon").style.display = "none";
        header.querySelector(".x-icon").style.display = "inline";
      }
    });
  });
});
